import { useEffect, useState } from "react"
import CommandCenterService from "../../../service/CommandCenterService"
import useUrlParams from "./useUrlParams"

type Orbit = {
    documentMetadata : any,
    groundStation : any,
    areaOfInterest : any,
    groundTarget : any,
    satToGsConnection : any,
    satToGTConnection : any,
    satellite : any
}

const useOrbit = () => {
    const [orbitData, setOrbitData] = useState<Orbit | null>(null)
    const { dashboard, scenario_id, truetwin_id } = useUrlParams()

    const getOrbitDetails = () => {
        const propagation = dashboard === 'summary' ? 'summary' : 'dashboard'
        CommandCenterService.getOribtData(scenario_id, truetwin_id, propagation).then(res => {
            if (res?.data) {
                setOrbitData(res?.data)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (dashboard && scenario_id && truetwin_id) {
            getOrbitDetails()
        }
    }, [dashboard, scenario_id, truetwin_id])

    return { orbitData }
}

export default useOrbit