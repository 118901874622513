import { useEffect, useState } from 'react';
import MissionService from '../../../service/MissionService';
import { toast } from "react-toastify";
import './toastPopup.css';
import { cleanDigitSectionValue } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';

const useFeature = () => {
    const [managerData, setManagerData] = useState([]);
    const [featuresData, setFeaturesData] = useState([]);

    //post all features
    const postFeatureData = async (id, formData, action) => {

        try {
            await MissionService.postFeature(id, formData);
            if (action === "draw") {
                toast.success("Feature created successfully!", { className: 'custom-toast-success' });
            } else if (action === "upload") {
                toast.success(`Shapefile uploaded successfully`, {
                    className: 'custom-toast-success',
                });
            }

        } catch (err) {
            console.log(err.message);
        }
    };
    //get all feature managers
    const getFeatureManager = async (id) => {
        try {
            const res = await MissionService.getAoiManagersByMissionId(id);
            setManagerData(res);
            return res.data;
        } catch (err) {
            console.log(err);
        } finally {
            console.log("data got succesfully");
        }
    };
    //after clicking on the manager get all its feature
    const getFeaturesOfManager = async (aoiManager_id) => {
        try {
            const res = await MissionService.getAllAoisByManagerId(aoiManager_id);
            setFeaturesData(res);
            return res.data;
        } catch (err) {
            console.log(err);
        } finally {
            console.log("data got succesfully");
        }
    };
    //delete the manager
    const deleteAoiManager = async (aoiManager_id) => {
        try {
            const response = await MissionService.deleteAoiManagerByManagerId(aoiManager_id);
            if (response && response.status === 200) {
                console.log(`AOI Manager with ID ${aoiManager_id} deleted successfully.`);
            } else {
                console.log(`Failed to delete AOI Manager with ID ${aoiManager_id}.`);
            }
        } catch (error) {
            console.error('Error deleting AOI Manager:', error);
        }
    };

    //delete the indivisual feature
    const deleteAoi = async (aoiId) => {
        try {
            const response = await MissionService.deleteAoiById(aoiId);
            if (response && response.status === 200) {
                console.log(`AOI Manager with ID ${aoiId} deleted successfully.`);
            } else {
                console.log(`Failed to delete AOI Manager with ID ${aoiId}.`);
            }
        } catch (error) {
            console.error('Error deleting AOI Manager:', error);
        }
    };
    //single aoi data by its id
    const getAoiData = async (aoiId) => {
        try {
            const res = await MissionService.getAoiById(aoiId);
            return res.data;
        } catch (err) {
            console.log(err);
        } finally {
            console.log("data got succesfully");
        }
    };
    //edit button for indivisual feature
    const updateFeatureData = async (aoiId, data, action) => {
        try {

            await MissionService.updateAoiById(aoiId, data);
            if (action === "coordinatesEdit") {
                toast.success(`Feature edited successfully!`, { className: 'custom-toast-success' });
            }

        } catch (err) {
            console.error('Error updating feature:', err.message);
        } finally {
        }
    };
    //get all aoi by its type (eg geofence)
    const getAllAoiByType = async (misson_id, type) => {
        try {
            const res = await MissionService.getAllAoisByType(misson_id, type);
            return res.data;
        } catch (err) {
            console.log(err);
        } finally {
            console.log("data got succesfully");
        }
    };
    //get all features by mission id
    const getAllAoiByMissionId = async (misson_id) => {
        try {
            const res = await MissionService.getAllAoisByMissionId(misson_id);
            return res.data;
        } catch (err) {
            console.log(err);
        } finally {
            console.log("data got succesfully");
        }
    };


    return {
        managerData,
        setManagerData,
        featuresData,
        setFeaturesData,
        postFeatureData,
        getFeatureManager,
        getFeaturesOfManager,
        deleteAoiManager,
        deleteAoi,
        updateFeatureData,
        getAoiData,
        getAllAoiByType,
        getAllAoiByMissionId
    };
};
export default useFeature;