//@ts-nocheck
import './Console.scss'
import { CollapseSvg, PinSvg } from '../Svgs';
import { useEffect, useState } from 'react';
import TTCFormSection from './TTCFormSection';
import TaskForm from './TaskForm';
import ConsoleOutputSection from './ConsoleOutputSection';
import FormSubmit from './FormSubmit';
import Modal from '../Modal';
import useModals from '../../../../hooks/useModals';
import { useSearchParams } from 'react-router-dom';
import useConsole from '../../../../hooks/useConsole';

const Console = () => {
  const { data, loading, error, fetchData, commandDetails, getCommandDetails, postData, busComponentOptions, fetchBusCommands, busCommandOptions, allPayloadComponents, lastList, setLastList, cursor, setCursor, jumpToFirstPage, loadMoreItems } = useConsole();
  const [resizing, setResizing] = useState(false);
  const [searchParams] = useSearchParams();
  const [modal, setModal] = useState({
    open: false,
    pinned: false,
    current_modal: ''
  })

  const { getModalDetails, handleUpdateUrl } = useModals()
  const [openPanel, setOpenPanel] = useState({
    left: true,
    right: true
  })

  const [communicationType, setCommunicationType] = useState<string>('TT&C')
  const [commandType, setCommandType] = useState<string>('Bus')

  const open_panel = (panel: string) => {
    setOpenPanel((prev) => ({
      left: panel === 'left' ? !prev.left : (prev.left || prev.right) ? true : false,
      right: panel === 'right' ? !prev.right : (prev.right || prev.left) ? true : false
    }))
  }

  const close_modal = (event: any) => {
    event.stopPropagation()
    handleUpdateUrl('console', 'close')
  }

  const pinned_modal = (event: any) => {
    event.stopPropagation()
    if (modal.pinned) {
      handleUpdateUrl('console', 'open')
    } else {
      handleUpdateUrl('console', 'pinned')
    }
  }

  const open_modal = (event: any) => {
    event.stopPropagation()
    if (modal.pinned) {
      handleUpdateUrl('console', 'pinned')
    } else {
      handleUpdateUrl('console', 'open')
    }
  }

  useEffect(() => {
    const details = getModalDetails('console')
    setModal(details)
  }, [searchParams])

  useEffect(() => {
    const close_modal = () => {
      const details = getModalDetails('console')
      if (details.open && !details.pinned) {
        handleUpdateUrl('console', 'close')
      }
    }
    window.addEventListener('click', close_modal)
    return () => {
      window.removeEventListener('click', close_modal)
    }
  }, [])

  const payload = {
    title: 'foo',
    body: 'bar',
    userId: 1,
  };

  return (
    <Modal
      open={modal.open}
      pinned={modal.current_modal === 'console' && modal.pinned}
      disabled={modal.current_modal === 'console' && modal.pinned || resizing}
      class_name={`${modal.current_modal === 'console' ? 'current__modal' : ''}`}
    >
      <div className={`console__container`} onClick={open_modal}>
        <div className={`console__container__left__panel ${openPanel.left ? 'active' : ''}`}>
          <div className='console__container__left__panel__container'>
            <div className='left__panel__top__container'>
              <div className='left__panel__header'>
                <span className='header__title'>Console</span>
                <div className='d-flex align-items-center gap-1'>
                  <button className='left__panel__colapse__btn' onClick={(event: any) => {
                    event.stopPropagation()
                    open_panel('left')
                  }}>
                    <CollapseSvg />
                  </button>
                  <button className={`left__panel__pin__btn ${modal.pinned && 'pinned'} ${openPanel.right && 'd-none'}`} onClick={pinned_modal}>
                    <PinSvg />
                  </button>
                  <button className={`left__panel__close__btn ${!openPanel.right ? 'd-flex' : 'd-none'}`} onClick={close_modal}>
                    <i className="fe fe-x fs-16" />
                  </button>
                </div>
              </div>
              <div className='left__panel__body' onClick={(event: any) => { event.stopPropagation() }}>
                <div className='left__panel__body__communication__btn__container'>
                  {['TT&C', 'TASK'].map(type => (
                    <button disabled={type === 'TASK'} className={`communication_btn ${communicationType === type && 'active'}`} key={type} onClick={() => { setCommunicationType(type) }}>{type}</button>
                  ))}
                </div>
                {communicationType === 'TT&C' &&
                  <TTCFormSection
                    communication_type={communicationType}
                    command_type={commandType}
                    set_command_type={(type) => { setCommandType(type) }}
                    busComponentOptions={busComponentOptions}
                    fetchBusCommands={fetchBusCommands}
                    busCommandOptions={busCommandOptions}
                    allPayloadComponents={allPayloadComponents}
                  />
                }
                {communicationType === 'TASK' &&
                  <TaskForm />
                }
              </div>
            </div>
            <div className='left__panel_bottom__container'>
              <FormSubmit payload={payload} postData={postData} />
            </div>
          </div>
        </div>
        <div className={`console__container__right__panel ${openPanel.right ? 'active' : ''}`}>
          <div className='console__container__right__panel__container'>
            <div className='right__panel__header'>
              <span className='header__title'>Message</span>
              <div className='d-flex align-items-center gap-1'>
                <button className='right__panel__colapse__btn' onClick={(event: any) => {
                  event.stopPropagation()
                  open_panel('right')
                }}>
                  <CollapseSvg />
                </button>
                <button className={`right__panel__pin__btn ${modal.pinned && 'pinned'} ${!openPanel.right && 'd-none'}`} onClick={pinned_modal}>
                  <PinSvg />
                </button>
                <button className={`right__panel__close__btn ${openPanel.right ? 'd-flex' : 'd-none'}`} onClick={close_modal}>
                  <i className="fe fe-x fs-16" />
                </button>
              </div>
            </div>
            <div className='right__panel__body'>
              {/* <div className='right__panel__body__header'>
                <button className={`header__badge__btn active`}>IQ Spacecom Xlink-S</button>
                <button className={`header__badge__btn`}>IQ Spacecom Xlink-X</button>
                <button className={`header__badge__btn`}>OpenLST-A UHF</button>
              </div> */}
              <ConsoleOutputSection
                data={data}
                loading={loading}
                error={error}
                commandDetails={commandDetails}
                getCommandDetails={getCommandDetails}
                set_resizing={setResizing}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Console