import { Fragment, useState, useEffect } from "react";
import './Panel.css'
import { panelPublicUrl } from "../../../../panelurl";

interface Props {
    panels: any;
    monitor_view_type: string;
}

const ADCS: React.FC<Props> = ({ panels, monitor_view_type }) => {


    const [adcType, setadcType] = useState('');
    const [adcTypes, setadcTypes] = useState<string[]>([]);

    useEffect(() => {
        if (panels && Object.keys(panels)?.length > 0) {
            let types: string[] = []
            let selected_type: string = ''
            Object.keys(panels).map((type) => {
                types.push(type)
                selected_type = selected_type === '' ? type : selected_type
            })
            setadcType(selected_type)
            setadcTypes(types)
        }
    }, [panels])

    return (
        <Fragment>
            <div className="d-flex monitor__container__body__header">
                <div className='buttonwrapper'>
                    {adcTypes.map((btn: string) => (
                        <button
                            //disabled={btn === 'SBand'}
                            className={`button ${adcType === btn && 'active'}`}
                            onClick={() => setadcType(btn)}
                        >
                            {btn}
                        </button>
                    ))}
                </div>
            </div>


            <div className={`${monitor_view_type === 'Grid' ? 'monitor__grid__view' : 'monitor__list__view'}`}>

                {adcType === 'State' && panels[adcType].map((panel: { panel_name: string, url: string }) => (
                    <div className='monitor__item__containe' key={panel.panel_name}>
                        <iframe src={`${panelPublicUrl}${panel?.['url']}`} className='panel__frame' />
                    </div>
                ))}

                {adcType === 'Measurements' && (panels[adcType]) && Object.keys(panels[adcType])?.map((panel) => (
                    <div className='monitor__item__containe' key={panel}>
                        <iframe src={`${panelPublicUrl}${panels[adcType][panel]?.[0]?.['url']}`} className='panel__frame' />
                    </div>
                ))}

            </div>
        </Fragment>
    )
}

export default ADCS