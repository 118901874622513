import { FC, Fragment } from "react";
import useOrbitParams from "../../hooks/useOrbitParams";
import CustomLoader from "../../custom_components/CustomLoader";
import { get_formated_time } from "../../utils";

interface Props {
    close: (e: any) => void;
    open: boolean;
}

const ScenariInfoContainer: FC<Props> = ({ open, close }) => {

    const { orbitParams, loading } = useOrbitParams()

    return (
        <div className={`scenario__orbit__info__container ${open && 'active'}`}>
            <CustomLoader loading={loading} />
            {!loading &&
                <Fragment>
                    <div className="scenario__orbit__info__header">
                        <span className="info__title">Orbit Details</span>
                        <button className="close_button" onClick={close}>
                            <i className="fe fe-x fs-16 close_icon" />
                        </button>
                    </div>
                    <div className="scenario__orbit__info__details__container">
                        <div className="container__left__section">
                            <div className="info__details">
                                <span>{orbitParams?.['epoch'] && `${orbitParams?.['epoch'] && get_formated_time(orbitParams?.['epoch'])}`}</span>
                                <span>Epoch</span>
                            </div>
                            <div className="info__details">
                                <span>{orbitParams?.['altitude']} km</span>
                                <span>Altitude</span>
                            </div>
                            <div className="info__details">
                                <span>{orbitParams?.['eccentricity']}</span>
                                <span>Eccentricity</span>
                            </div>
                            <div className="info__details">
                                <span>{orbitParams?.['RAAN']}°</span>
                                <span>Longitude ascending node</span>
                            </div>
                        </div>
                        <div className="container__right__section">

                            <div className="info__details">
                                <span>{orbitParams?.['TA']}°</span>
                                <span>True Anomaly</span>
                            </div>
                            <div className="info__details">
                                <span>{orbitParams?.['inclination']}°</span>
                                <span>Inclination</span>
                            </div>
                            <div className="info__details">
                                <span>{orbitParams?.['AP']}°</span>
                                <span>Argument of periapsis</span>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    )
}

export default ScenariInfoContainer