import './Hud.css'
import Contact from '../hud/contact/Contact'
import CesiumContainer from './cesium/CesiumContainer';
import useUrlParams from '../../../hooks/useUrlParams';

const Hud = () => {

    const { dashboard } = useUrlParams()

    return (
        <div className='hud__container'>
            <CesiumContainer />
            {dashboard !== 'quick-deploy' && <Contact />}
        </div>
    )
}

export default Hud