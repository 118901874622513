import { FC } from "react";
import Map from "./Map";
import { ContactStation } from "../../../../hooks/useGroundContact";

interface Props {
    open: boolean;
    close: (data: boolean) => void;
    selected_contact: {
        contact_type: string;
        contact_data: ContactStation,
    } | null
}

const ContactModal: FC<Props> = ({ open, close, selected_contact }) => {

    return (
        <div className={`contact__modal__container ${open && 'show__contact__modal'}`}>
            <div className="contact__visualization__container">
                <button className="close__btn" onClick={() => { close(false) }}>
                    <i className="fe fe-x fs-20" />
                </button>
                <Map selected_contact={selected_contact} />
            </div>
        </div>
    )
}

export default ContactModal