import { ReactNode, createContext, useEffect, useState } from "react";
import useUrlParams from "../hooks/useUrlParams";
import CommandCenterService from "../../../service/CommandCenterService";
import useError from "../hooks/useError";

interface ScenarioContextType {
    scenario: any;
    TrueTwin: any;
    loading: boolean;
    setScenario: (scenario: any) => void;
    setTrueTwin: (TrueTwin: any) => void;
    setLoading: (loading: boolean) => void;
}

export const ScenarioContext = createContext<ScenarioContextType | undefined>(undefined);

interface ScenarioProviderProps {
    children: ReactNode;
}
const ScenarioProvider = ({ children }: ScenarioProviderProps) => {
    const { scenario_id, truetwin_id } = useUrlParams()
    const [scenario, setScenario] = useState<any>({});
    const [TrueTwin, setTrueTwin] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const { setError } = useError()

    useEffect(() => {
        if (scenario_id) {
            CommandCenterService.getScenarioData(scenario_id).then(res => {
                if (res?.data) {
                    setScenario(res.data)
                }
            }).catch(err => {
                const error_msg = err?.message
                const error_code = error_msg === 'Network Error' ? error_msg : '500'
                console.log(error_code)
                setError(error_code)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [scenario_id])

    useEffect(() => {
        CommandCenterService.getTrueTwinData(truetwin_id).then(res => {
            if (res?.data) {
                setTrueTwin(res.data)
            }
        }).catch((err: any) => {
            const error_msg = err?.message
            const error_code = error_msg === 'Network Error' ? error_msg : '500'
            console.log(error_code)
            setError(error_code)
        }).finally(() => {
            setLoading(false)
        })
    }, [truetwin_id])

    return (
        <ScenarioContext.Provider value={{ scenario, loading, setScenario, setLoading, TrueTwin, setTrueTwin }}>
            {children}
        </ScenarioContext.Provider>
    )
}

export default ScenarioProvider