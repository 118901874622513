export type SelectOption = {
    label: string;
    value: number | string;
}

export type FrameDetails = {
    frame_start_time: number;
    frame_end_time: number;
    frame_duration: number;
    fream_step: number;
    manual_change: boolean | string
}
export type MarkerDetails = {
    epoch: number;
    position: number;
}

export const contact_options = [
    {
        label: 'GROUND CONTACT',
        value: 'ground_contact'
    },
    {
        label: 'ON-BOARD SCHEDULE',
        value: 'on_board_schedule'
    },
    {
        label: 'GROUND & ON-BOARD',
        value: 'ground_&_on_board'
    },
]
export const time_options = [
    {
        label: '3 Hr Time frame',
        value: 180,
    },
    {
        label: '2 Hr Time frame',
        value: 120,
    },
    {
        label: '1 Hr Time frame',
        value: 60,
    },
    {
        label: '30 Min Time frame',
        value: 30,
    },
]

export const filter_options = [
    {
        label: 'Ground Stations',
        value: 'ground_station'
    },
    {
        label: 'Task',
        value: 'task'
    }
]

export const gs_inner_options = [
    {
        label: 'Ground Stations 1',
        value: 'ground_station 1'
    }, {
        label: 'Ground Stations 2',
        value: 'ground_station 2'
    }, {
        label: 'Ground Stations 3',
        value: 'ground_station 3'
    }, {
        label: 'Ground Stations 4',
        value: 'ground_station 4'
    }, {
        label: 'Ground Stations 5',
        value: 'ground_station 5'
    }
]

export const task_inner_options = [
    {
        label: 'Task 1',
        value: 'task 1'
    },
    {
        label: 'Task 2',
        value: 'task 2'
    },
    {
        label: 'Task 3',
        value: 'task 3'
    },
    {
        label: 'Task 4',
        value: 'task 4'
    },
    {
        label: 'Task 5',
        value: 'task 5'
    }
]

const start_time = new Date().getTime() / 1000
export const onboardtask = {
    'payloadtask': [
        {
            status: 'ongoing',
            name: 'payloadtask',
            starttime: start_time + 300,
            endtime: start_time + 540,
            duration: 4,
        },
        {
            status: 'not_confirm',
            name: 'payloadtask',
            starttime: start_time + 600,
            endtime: start_time + 960,
            duration: 6,
        },
        {
            status: 'confirm',
            name: 'payloadtask',
            starttime: start_time + 1140,
            endtime: start_time + 1200,
            duration: 1,
        },
        {
            status: 'upcoming',
            name: 'payloadtask',
            starttime: start_time + 1500,
            endtime: start_time + 1680,
            duration: 3,
        },
        {
            status: 'failed',
            name: 'payloadtask',
            starttime: start_time + 1800,
            endtime: start_time + 2400,
            duration: 10,
        },
    ],
    'satopstask': [
        {
            status: 'ongoing',
            name: 'satopstask',
            starttime: start_time + 200,
            endtime: start_time + 440,
            duration: 4,
        },
        {
            status: 'not_confirm',
            name: 'satopstask',
            starttime: start_time + 500,
            endtime: start_time + 860,
            duration: 6,
        },
        {
            status: 'confirm',
            name: 'satopstask',
            starttime: start_time + 1040,
            endtime: start_time + 1100,
            duration: 1,
        },
        {
            status: 'upcoming',
            name: 'satopstask',
            starttime: start_time + 1300,
            endtime: start_time + 1480,
            duration: 3,
        },
        {
            status: 'failed',
            name: 'satopstask',
            starttime: start_time + 1650,
            endtime: start_time + 2250,
            duration: 10,
        },
    ]
}
export const groundcontact = [
    {
        status: 'ongoing',
        name: 'groundstation',
        starttime: start_time + 300,
        endtime: start_time + 540,
        duration: 4,
    },
    {
        status: 'not_confirm',
        name: 'groundstation',
        starttime: start_time + 600,
        endtime: start_time + 960,
        duration: 6,
    },
    {
        status: 'confirm',
        name: 'groundstation',
        starttime: start_time + 1140,
        endtime: start_time + 1200,
        duration: 1,
    },
    {
        status: 'upcoming',
        name: 'groundstation',
        starttime: start_time + 1500,
        endtime: start_time + 1680,
        duration: 3,
    },
    {
        status: 'failed',
        name: 'groundstation',
        starttime: start_time + 1800,
        endtime: start_time + 2400,
        duration: 10,
    },
]

export const get_iso_date = (time) => {
    const date = new Date(time * 1000);
    const iso = date.toISOString().split('.')[0].replace('T', ' ');
    return iso
}

export const calculate_schedule_duration = (remainingTime) => {
    let minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
    let paddedMinutes = minutes;
    let paddedSeconds = seconds;
    return paddedSeconds === 0 ? `${paddedMinutes} Min` : `${paddedMinutes}.${paddedSeconds} Min`
}