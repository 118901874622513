import { panelPublicUrl } from '../../../../panelurl';
import './Panel.css'
interface Props {
    panels: any;
    monitor_view_type: string;
}
const OBC: React.FC<Props> = ({ panels, monitor_view_type }) => {

    return (
        <div className={`${monitor_view_type === 'Grid' ? 'monitor__grid__view' : 'monitor__list__view'}`}>
            {panels.map((panel: { panel_name: string, url: string }) => (
                <div className='monitor__item__containe' key={panel.panel_name}>
                    <iframe src={`${panelPublicUrl}${panel?.['url']}`} className='panel__frame' />
                </div>
            ))}
        </div>
    )
}

export default OBC