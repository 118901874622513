import './GroundContact.scss'
import { Fragment, useState } from 'react';
import GroundContactSection from './GroundContactSection';
import RightSidePanel from './RightSidePanel';
import ContactModal from './ContactModal';
import CustomSelect from '../../../../custom_components/CustomSelect';
import { time_options } from '../../hud/contact/utils';
import { NavigationButton } from '../utils';
import useUrlParams from '../../../../hooks/useUrlParams';
import useUrlNavigation from '../../../../hooks/useUrlNavigation';
import useScenario from '../../../../hooks/useScenario';
import useContacts from '../../../../hooks/useContacts';
import useTimeline from '../../../../hooks/useTimeline';
import useGroundContact from '../../../../hooks/useGroundContact';
import EmptyPage from '../../../../custom_components/EmptyPage';


const GroundContact = () => {
    const { contacts, selectedContact } = useContacts()
    const { tab_name } = useUrlParams()
    const { navigate_to } = useUrlNavigation()
    const { selected_timeframe, select_timeframe } = useTimeline()
    const [showModal, setShowModal] = useState<boolean>(false)
    const { contactData } = useGroundContact()
    const { scenario } = useScenario();

    return (
        <div className='planning__container__body'>
            {contactData?.length > 0 &&
                <div className='ground__station__contact__container'>
                    <Fragment>
                        <div className='ground__container__left__container__wrapper'>
                            <div className='ground__container__header__container'>
                                <div className='ground__container__header__left__container'>
                                    {NavigationButton.map((btn) => (
                                        <button className={`button ${tab_name === btn.name && 'active'}`}
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                e.stopPropagation()
                                                navigate_to(btn.name)
                                            }}>{btn.label}</button>
                                    ))}
                                </div>
                                <div className='ground__container__header__right__container'>
                                    <CustomSelect
                                        options={time_options}
                                        value={selected_timeframe}
                                        disabled_search={true}
                                        onChange={(data) => { select_timeframe(data) }} />
                                </div>
                            </div>
                            <GroundContactSection
                                simulation_start_time={scenario?.['simulationStartTime']}
                                simulation_end_time={scenario?.['simulationStartTime'] + (Number(scenario?.['simulationDuration']) * 60)}
                            />
                        </div>
                        <RightSidePanel set_show_modal={() => { setShowModal(!showModal) }} all_contacts={contacts} selected_contact={selectedContact} />
                    </Fragment>
                </div>
            }
            {contactData?.length === 0 &&
                <EmptyPage content='No Ground Contacts' />
            }


            {showModal && <ContactModal open={showModal} close={setShowModal} selected_contact={selectedContact} />}
        </div>
    )
}

export default GroundContact