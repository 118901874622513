import { Fragment, useEffect, useState } from 'react';
import CustomSelect from '../../custom_components/CustomSelect';
import useMonitor from '../../hooks/useMonitor';
import './Panel.css'
import { panelPublicUrl } from '../../../../panelurl';
interface Props {
    panels: any;
    monitor_view_type: string;
}

const Communication: React.FC<Props> = ({ panels, monitor_view_type }) => {

    const [communicationType, setCommunicationType] = useState<
        {
            label: string,
            value: string
        } | null
    >(null);

    const [communicationTypes, setCommunicationTypes] = useState<{
        label: string,
        value: string
    }[]>([]);

    const [communicationsubType, setCommunicationsubType] = useState<string>('');

    useEffect(() => {
        if (panels && Object.keys(panels)?.length > 0) {
            let types: {
                label: string,
                value: string
            }[] = []
            let selected_type: string = ''
            Object.keys(panels).map((type) => {
                types.push({
                    label: type,
                    value: type
                })
                selected_type = selected_type === '' ? type : selected_type
            })
            setCommunicationType({
                label: selected_type,
                value: selected_type
            })

            setCommunicationTypes(types)
        }
    }, [panels])

    useEffect(() => {
        if (communicationType && panels[communicationType.value]) {
            const subTypes = Object.keys(panels[communicationType.value]);
            if (subTypes.includes('uplink')) {
                setCommunicationsubType('uplink');
            } else if (subTypes.length > 0) {
                setCommunicationsubType(subTypes[0]);
            }
        }
    }, [communicationType, panels]);

    return (
        <Fragment>

            <div className="monitor__container__body__header">

                <div className='d-flex align-items-center justify-content-between'>
                    <CustomSelect options={communicationTypes} value={communicationType} onChange={(data) => { setCommunicationType(data) }} className="header__select__container" />

                    <div className='buttonwrapper'>
                        {communicationType && (panels[communicationType?.value]) && Object.keys(panels[communicationType.value])?.map((btn) => (
                            <button
                                className={`button ${communicationsubType === btn && 'active'}`}
                                onClick={() => setCommunicationsubType(btn)}
                            >
                                {btn}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className={`${monitor_view_type === 'Grid' ? 'monitor__grid__view' : 'monitor__list__view'}`}>

                {communicationType && panels[communicationType.value]?.[communicationsubType] && panels[communicationType.value]?.[communicationsubType]?.map((panel: { panel_name: string, url: string }) => (

                    <div className='monitor__item__containe' key={panel.panel_name}>
                        <iframe src={`${panelPublicUrl}${panel?.['url']}`} className='panel__frame' />
                    </div>
                ))}

            </div>
        </Fragment>
    )
}

export default Communication