import React, { FC, Fragment } from 'react'
import useUrlParams from '../../../../hooks/useUrlParams';
import { get_formated_time } from '../../../../utils';
import { ButtonSvg } from './Svgs';
import useTimeline from '../../../../hooks/useTimeline';
import useViewer from '../../../../hooks/useViewer';

interface ContactTimeFrameProps {
    simulation_start_time: number,
    simulation_end_time: number,
}

const ContactTimeFrame: FC<ContactTimeFrameProps> = ({
    simulation_start_time,
    simulation_end_time,
}) => {
    const { dashboard } = useUrlParams()
    const { change_viewer_time } = useViewer()
    const { timelist, marker, window_width, timeframe_details, change_frame } = useTimeline()

    const { frame_start_time, frame_end_time } = timeframe_details


    return (
        <div className="contact__timeframe__container" style={{ width: `${window_width}px` }}>
            <div className='timeframe__datetime__container'>
                {timelist.map(date_time => (
                    <div className='d-flex align-items-center gap-2' key={date_time.epoch}>
                        <div className="contact__timeframe__date">{date_time.date}</div>
                        <div className="contact__timeframe__time">{date_time.time}</div>
                    </div>
                ))}
            </div>

            {(marker.epoch && marker.epoch >= frame_start_time! && marker.epoch! <= frame_end_time!) &&
                <Fragment>
                    <span className="current__time__indicator" style={{ left: `${marker.position}px` }} />
                    <span style={{
                        right: (marker.position + 110) > window_width ? '0px' : 'none',
                        left: (marker.position - 110) < 0 ? '0px' : (marker.position + 110) > window_width ? 'none' : `${marker.position - 110}px`,
                    }}
                        className="indicator__tooltip">{`${get_formated_time(marker.epoch!)}`}</span>
                </Fragment>
            }
            {marker.epoch! &&
                <input
                    disabled={dashboard !== 'summary'}
                    type="range"
                    step='any'
                    min={frame_start_time!}
                    max={frame_end_time!}
                    value={marker.epoch!}
                    onChange={change_viewer_time}
                    className={`timeframe__slider__input ${(marker.epoch! >= frame_start_time! && marker.epoch! <= frame_end_time!) ? 'show_marker' : ''}`}
                />
            }
            <div className="timeframe__datetime__indicators">
                {[1, 2, 3].map(elem => (
                    <div key={elem} className="timeframe__datetime__indicator" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                            <path d="M10.7929 0.75H1.20711C0.761654 0.75 0.538571 1.28857 0.853554 1.60355L5.64645 6.39645C5.84171 6.59171 6.15829 6.59171 6.35355 6.39645L11.1464 1.60355C11.4614 1.28857 11.2383 0.75 10.7929 0.75Z" fill="#EC0F94" />
                        </svg>
                    </div>
                ))}
            </div>
            {['left', 'right'].map(elem => (
                <button
                    disabled={(elem === 'left' && simulation_start_time === frame_start_time) || (elem === 'right' && simulation_end_time === frame_end_time)}
                    key={elem}
                    className={`timeframe__change__button ${elem}`}
                    onClick={() => { change_frame(elem, simulation_start_time, simulation_end_time) }}
                >
                    <ButtonSvg />
                </button>
            ))}
        </div>
    )
}

export default ContactTimeFrame