//@ts-nocheck
import { useState, useEffect } from 'react';
import { Canvas } from 'react-three-fiber';
import { OrbitControls } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import MissionService from '../../../../../service/MissionService';
import Common3DSat from "../../../../CommonComponents/Common3DSat";
import Loader from '../../../../../Layouts/Loader/Loader';

const FileUploader = ({ Satellite, busSizeUsage, payloadSizeUsage, isCloneTemplate, setLoading }) => {
    const [file, setFile] = useState(null);
    const [model, setModel] = useState(null);
    const [isModelPresent, setModelPresent] = useState(false)
    const [modelName, setModelName] = useState()
    const [load3Dmodel, loadSatModel] = useState(true)
    const [hover, setHover] = useState(false)


    const saveModel = async (fileData) => {
        const formData = new FormData();
        formData.append('object', fileData);
        const blob = new Blob([fileData], { type: "multipart/form-data" });
        formData.append("file", blob, fileData);
        setLoading(true)
        MissionService.postThreedModel(Satellite.satellite_Id, formData)
            .then((res) => {
                if (res) {
                    MissionService.get_satellite_by_sat_id(Satellite.satellite_Id)?.then((sat) => {
                        if (sat?.data) {
                            setModelName(sat.data.modelNames.modelName)
                            setLoading(false)
                        }
                    })
                }
            })
            .catch((err) => {
                console.log(err, "Could not save the file, please check");
                setLoading(false)
            });
    };

    useEffect(() => {
        MissionService.get_satellite_by_sat_id(Satellite.satellite_Id)?.then((sat) => {
            if (sat?.data) {
                let satMldelName = sat.data.modelNames.modelName
                setModelName(satMldelName)
                MissionService.getModelUrl(Satellite.satellite_Id, satMldelName).then((modelUrl) => {
                    if (modelUrl?.data) {
                        // setModelPresent(true)
                        loadModel(modelUrl.data)
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [isModelPresent == true])

    const handleButtonClick = () => {
        document.getElementById('file-input').click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            saveModel(selectedFile)
            const loader = new GLTFLoader()
            loader.load(URL.createObjectURL(selectedFile), (gltf) => {
                setModel(gltf.scene);
            });
        }
    };

    const loadModel = async (modelUrl) => {
        const loader = new GLTFLoader();
        loader.load(modelUrl, (gltf) => {
            setModel(gltf.scene);
        });
        loadSatModel(false)
    }

    const checkIfFileInBucket = async () => {
        MissionService.getModelUrl(Satellite.satellite_Id, modelName).then((modelUrl) => {
            if (modelUrl?.data) {
                setModelPresent(true)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        checkIfFileInBucket();
    }, [])

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <input
                id="file-input"
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            <div
                onClick={handleButtonClick}
                onMouseEnter={() => {
                    setHover(true)
                }}
                onMouseLeave={() => {
                    setHover(false)
                }}
                className="btn btn-outline-primary z-99"
                style={{
                    position: 'absolute',
                    top: 0,
                    right: '50px',
                    left: 'auto',
                    display: 'inline-flex',
                    height: 'var(--40, 40px)',
                    padding: '8px var(--12, 12px)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 'var(--12, 12px)',
                    borderRadius: '4px',
                }}
            >
                <img src={require('../../../../../assets/images/svgs/upload_model.svg').default} alt="Upload Model" style={{ opacity: 0.6, width: '20px', height: '20px' }} />
                <span style={{
                    color: '#FFF',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal'
                }}>
                    3D Model
                </span>

            </div>
            <div
                id='infoDiv'
                style={{
                    position: 'absolute',
                    top: '50px',
                    right: '50px',
                    display: hover ? 'flex' : 'none',
                    padding: '12px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '12px',
                    borderRadius: 'var(--8, 8px)',
                    opacity: '0px',
                    background: 'rgba(240, 240, 240, 0.10)',
                    backdropfilter: 'blur(20px)',

                }}>
                <img src={require('../../../../../assets/images/svgs/upload-btn-info.svg').default} alt="Upload Model Info" style={{ width: 'var(--16, 16px)', height: 'var(--16, 16px)' }} />
                <span style={{
                    color: '#FFF',
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                }}>
                    File format should be glb/gltf,
                    with maximum file size of 25 MB
                </span>
            </div>

            {(file || model) && (
                <Canvas camera={{ position: [10, 10, 15], fov: 75 }}>
                    <ambientLight />
                    <pointLight position={[10, 0, 10]} />
                    <OrbitControls />
                    {model && <primitive object={model} />}
                </Canvas>
            )}

            {!isModelPresent &&
                <Common3DSat busSize={Satellite.Template?.['template-attribute']?.['bus-type']} size={busSizeUsage + payloadSizeUsage} heights="100vh" isPayloadChanges="false" cubeSize={isCloneTemplate ? 0 : 1} />
            }
        </div>
    );
}

export default FileUploader;
