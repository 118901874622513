
import useGroundContact, { ContactStation } from "../../../../hooks/useGroundContact";
import CustomToolTip from "../../../../../CommonComponents/CustomToolTip";
import './ContactTimeline.scss'
import { ButtonToolbar, OverlayTrigger } from "react-bootstrap";
import { GroundTaskSvg } from "../../modals/Svgs";
import { event_status_state } from "./Svgs";
import useViewer from "../../../../hooks/useViewer";
import { get_formated_time } from "../../../../utils";
import useTimeline from "../../../../hooks/useTimeline";


const GroundContact = () => {
    const { contactData } = useGroundContact()
    const { current_simulation_time } = useViewer()
    const { window_width, timeframe_details } = useTimeline()
    const { frame_start_time, frame_duration } = timeframe_details

    const get_current_contact = (start_time: number, end_time: number) => {
        const current_time = current_simulation_time / 1000
        if (current_time >= start_time && current_time <= end_time) {
            return 'ongoing__event'
        } else if (current_time < start_time) {
            return 'upcomming__event'
        } else if (current_time > end_time) {
            return 'past__event'
        }
    }

    const get_contact_status = (start_time: number, end_time: number) => {
        const current_time = current_simulation_time / 1000
        if (current_time >= start_time && current_time <= end_time) {
            return 'ongoing'
        } else if (current_time < start_time) {
            return 'upcoming'
        } else if (current_time > end_time) {
            return 'complete'
        } else {
            return 'not_confirm'
        }
    }

    return (
        <div className="task__details__container" style={{ width: `${window_width}px` }}>
            {contactData.map((station: ContactStation, stationIndex) => (
                station?.passes.map((contact, contactIndex) => {
                    const contact_start_time = contact?.[0]
                    const contact_end_time = contact?.[1];
                    const contact_duration = contact?.[2];
                    const per_sec_pixel = window_width / frame_duration;
                    const card__width = (contact_duration * 60) * per_sec_pixel;
                    const left_position = (contact_start_time - frame_start_time) * per_sec_pixel;
                    const contact_status = get_contact_status(contact_start_time, contact_end_time)
                    const contact_type = get_current_contact(contact_start_time, contact_end_time)

                    return (
                        <ButtonToolbar key={`${stationIndex - contactIndex}`}>
                            <OverlayTrigger delay={50} placement="top" trigger={['hover', 'focus']}
                                overlay={(
                                    <div className={`contacts_overview`}>
                                        <div className='d-flex gap-2 align-items-center w-100 justify-content-between'>
                                            <div className="contact_info w-70">
                                                <div className="d-flex gap-2">
                                                    <GroundTaskSvg />
                                                    <div className="contact_title">
                                                        <span>
                                                            <CustomToolTip placement="top" title={`${station?.['name']}, ${station?.['country']}`}>
                                                                {`${station?.['name']}, ${station?.['country']}`}
                                                            </CustomToolTip>
                                                        </span>
                                                    </div>
                                                </div>
                                                <span>{station?.['provider']}</span>
                                            </div>
                                            <div className={`task__status__badge ${contact_type === 'ongoing__event' && 'ongoing'}`}>
                                                {contact_type === 'ongoing__event' ? 'Ongoing' : contact_type === 'upcomming__event' ? 'Upcoming' : 'Past'}
                                            </div>
                                        </div>
                                        <div className='d-flex w-100 align-items-center justify-content-between'>
                                            <div className='contact_info'>
                                                <span>{contact_start_time && `${get_formated_time(contact_start_time)}`}</span>
                                                <span>Start Time</span>
                                            </div>
                                            <div className='contact_info'>
                                                <span>{contact_duration && `${contact_duration} Min`}</span>
                                                <span>Total Duration</span>
                                            </div>
                                        </div>
                                        <div className="opacity-50 fs-16">Contact Details</div>
                                        <div className='d-flex w-100 align-items-center justify-content-between'>
                                            <div className='contact_info'>
                                                <span>{station?.['band'].map((band, index) => (
                                                    <span key={band} className="band">{band}{index !== (station?.band?.length - 1) && ', '}</span>
                                                ))}</span>
                                                <span>Band</span>
                                            </div>
                                            <div className='contact_info'>
                                                <span>{station?.['latitude']}</span>
                                                <span>Latitude</span>
                                            </div>
                                            <div className='contact_info'>
                                                <span>{station?.['longitude']}</span>
                                                <span>Longitude</span>
                                            </div>
                                            <div className='contact_info'>
                                                <span>{station?.['location']}</span>
                                                <span>Location</span>
                                            </div>
                                        </div>
                                    </div>
                                )}>
                                <div className={`task__details__card ${contact_type}`}
                                    style={{
                                        transform: `translateX(${left_position}px)`,
                                        width: `${card__width}px`,
                                    }}
                                >
                                    <div className="event__indicator">
                                        <CustomToolTip title={event_status_state[contact_status].state} placement="top">
                                            {event_status_state[contact_status].svg}
                                        </CustomToolTip>
                                    </div>
                                    <div className="w-100 d-flex h-100 align-items-center justify-content-center">
                                        {card__width >= 25 &&
                                            <div className="task__card__container" style={{ width: `${card__width}px` }}>
                                                <div className="task_card_svg_container">
                                                    <GroundTaskSvg />
                                                </div>
                                                <div className={`task__title ${card__width > 50 && 'show'}`}>{station?.name}</div>
                                            </div>
                                        }
                                        {card__width < 25 && <div style={{ width: '20px', height: '30px' }} />}
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </ButtonToolbar>
                    )
                })
            ))}
        </div>
    )
}

export default GroundContact